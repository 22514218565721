{
    const alertBtn = document.getElementById('btn-alert');
    if (alertBtn) {
        alertBtn.addEventListener('click', Event => {
            var mode = document.getElementById('mode').value;
            if (mode == 'insert') {
                document.f_alert.action = "../partials/alert-insert.php";
                document.f_aler.submit();
                document.f_aler.action = "";
            } else {
                document.f_alert.action = "../partials/alert-update.php";
                document.f_aler.submit();
                document.f_aler.action = "";
            }

        })

    }
}

{
    const alertDelBtn = document.querySelectorAll('.alert-del');

    if (alertDelBtn) {
        alertDelBtn.forEach(element => {
            element.addEventListener('click', event => {
                event.preventDefault();
                const target = event.target;
                const data = target.dataset;

                var xhr = new XMLHttpRequest();
                var url = '../partials/delAlert.php?val=' + data.alertid;
                xhr.open('GET', url);
                xhr.send();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        if (xhr.responseText == false) {
                            alert('削除できませんでした。');
                        } else {
                            alert('削除しました。');
                            location.href = "/?page=alert"
                        }

                    }
                }
            })
        });
    }
}

{
    const alertTestBtn = document.getElementById('btn-alert-test');
    if (alertTestBtn) {
        alertTestBtn.addEventListener('click', Event => {
            document.f_alert.target = "_blank";
            document.f_alert.action = "../partials/set-parametar.php?alert";
            document.f_aler.submit();
            document.f_aler.action = "";
            document.f_alert.target = "_self";
        })
    }
}
{
    //CSV出力
    const csv_exp = document.getElementById('csv-exp-alert');
    if (csv_exp) {
        csv_exp.addEventListener('click', Event => {
            document.f_playlist.action = "../pages/csvExp.php";
            document.f_playlist.submit();
            document.f_playlist.action = "";
        })

    }
}

  